<template>
  <v-container :fill-height="loading" class="start-view">

    <hb-app-bar></hb-app-bar>

    <hb-loading-indicator v-if="loading" align-middle />

    <v-fade-transition>
      <v-row no-gutters>
        <v-col cols="12">
          <dummy-block />
        </v-col>
        <v-col cols="12">
          <v-icon class="mr-5">mdi-check</v-icon>
          <v-icon class="mr-5">$vuetify.icons.complete</v-icon>
          complete <br />
          <v-icon class="mr-5">mdi-close-circle</v-icon>
          <v-icon class="mr-5">$vuetify.icons.cancel</v-icon>
          cancel<br />
          <v-icon class="mr-5">mdi-close</v-icon>
          <v-icon class="mr-5">$vuetify.icons.close</v-icon>
          close<br />
          <v-icon class="mr-5">mdi-close-circle</v-icon>
          <v-icon class="mr-5">$vuetify.icons.delete</v-icon>
          delete <br />
          <v-icon class="mr-5">mdi-close</v-icon>
          <v-icon class="mr-5">$vuetify.icons.clear</v-icon>
          clear <br />
          <v-icon class="mr-5">mdi-check-circle</v-icon>
          <v-icon class="mr-5">$vuetify.icons.success</v-icon>
          success <br />
          <v-icon class="mr-5">mdi-information</v-icon>
          <v-icon class="mr-5">$vuetify.icons.info</v-icon>
          info <br />
          <v-icon class="mr-5">mdi-exclamation</v-icon>
          <v-icon class="mr-5">$vuetify.icons.warning</v-icon>
          warning <br />
          <v-icon class="mr-5">mdi-alert</v-icon>
          <v-icon class="mr-5">$vuetify.icons.error</v-icon>
          error <br />
          <v-icon class="mr-5">mdi-chevron-left</v-icon>
          <v-icon class="mr-5">$vuetify.icons.prev</v-icon>
          prev <br />
          <v-icon class="mr-5">mdi-chevron-right</v-icon>
          <v-icon class="mr-5">$vuetify.icons.next</v-icon>
          next <br />
          <v-icon class="mr-5">mdi-checkbox-marked</v-icon>
          <v-icon class="mr-5">$vuetify.icons.checkboxOn</v-icon>
          checkboxOn <br />
          <v-icon class="mr-5">mdi-checkbox-blank-outline</v-icon>
          <v-icon class="mr-5">$vuetify.icons.checkboxOff</v-icon>
          checkboxOff <br />
          <v-icon class="mr-5">mdi-minus-box</v-icon>
          <v-icon class="mr-5">$vuetify.icons.checkboxIndeterminate</v-icon>
          checkboxIndeterminate <br />
          <v-icon class="mr-5">mdi-circle</v-icon>
          <v-icon class="mr-5">$vuetify.icons.delimiter</v-icon>
          delimiter <br />
          <v-icon class="mr-5">mdi-arrow-up</v-icon>
          <v-icon class="mr-5">$vuetify.icons.sort</v-icon>
          sort <br />
          <v-icon class="mr-5">mdi-chevron-down</v-icon>
          <v-icon class="mr-5">$vuetify.icons.expand</v-icon>
          expand <br />
          <v-icon class="mr-5">mdi-menu</v-icon>
          <v-icon class="mr-5">$vuetify.icons.menu</v-icon>
          menu <br />
          <v-icon class="mr-5">mdi-menu-down</v-icon>
          <v-icon class="mr-5">$vuetify.icons.subgroup</v-icon>
          subgroup <br />
          <v-icon class="mr-5">mdi-menu-down</v-icon>
          <v-icon class="mr-5">$vuetify.icons.dropdown</v-icon>
          dropdown <br />
          <v-icon class="mr-5">mdi-radiobox-marked</v-icon>
          <v-icon class="mr-5">$vuetify.icons.radioOn</v-icon>
          radioOn <br />
          <v-icon class="mr-5">mdi-radiobox-blank</v-icon>
          <v-icon class="mr-5">$vuetify.icons.radioOff</v-icon>
          radioOff <br />
          <v-icon class="mr-5">mdi-pencil</v-icon>
          <v-icon class="mr-5">$vuetify.icons.edit</v-icon>
          edit <br />
          <v-icon class="mr-5">mdi-star-outline</v-icon>
          <v-icon class="mr-5">$vuetify.icons.ratingEmpty</v-icon>
          ratingEmpty <br />
          <v-icon class="mr-5">mdi-star</v-icon>
          <v-icon class="mr-5">$vuetify.icons.ratingFull</v-icon>
          ratingFull <br />
          <v-icon class="mr-5">mdi-star-half</v-icon>
          <v-icon class="mr-5">$vuetify.icons.ratingHalf</v-icon>
          ratingHalf <br />
          <v-icon class="mr-5">mdi-cached</v-icon>
          <v-icon class="mr-5">$vuetify.icons.loading</v-icon>
          loading <br />
          <v-icon class="mr-5">mdi-page-first</v-icon>
          <v-icon class="mr-5">$vuetify.icons.first</v-icon>
          first <br />
          <v-icon class="mr-5">mdi-page-last</v-icon>
          <v-icon class="mr-5">$vuetify.icons.last</v-icon>
          last <br />
          <v-icon class="mr-5">mdi-unfold-more-horizontal</v-icon>
          <v-icon class="mr-5">$vuetify.icons.unfold</v-icon>
          unfold <br />
          <v-icon class="mr-5">mdi-paperclip</v-icon>
          <v-icon class="mr-5">$vuetify.icons.file</v-icon>
          file <br />
          <v-icon class="mr-5">mdi-plus</v-icon>
          <v-icon class="mr-5">$vuetify.icons.plus</v-icon>
          plus <br />
          <v-icon class="mr-5">mdi-minus</v-icon>
          <v-icon class="mr-5">$vuetify.icons.minus</v-icon>
          minus <br />
        </v-col>
      </v-row>
    </v-fade-transition>

  </v-container>
</template>

<script>
  import DummyBlock from '../components/Start/DummyBlock.vue';

  export default {
    name: "StartView",
    components: {
      DummyBlock: DummyBlock,
    },
    data() {
      return {
        loading: true,
        searchPhrase: '',
      }
    },
    mounted() {
      this.loading = false;
    },
  }
</script>

<style scoped>

</style>
