import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VImg,{attrs:{"src":_vm.randomDummyPicUrl,"aspect-ratio":"2.75"},on:{"error":_vm.randomizeDummyPicUrl}}),_c(VCardTitle,{attrs:{"primary-title":""}},[_c('div',[_c('h3',{staticClass:"headline mb-0"},[_vm._v("Kangaroo Valley Safari")]),_c('div',[_vm._v(" Lorem ipsum dolor sit amet null. ")])])]),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"secondary"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("fas fa-share-alt")]),_vm._v(" Share")],1),_c(VBtn,{attrs:{"text":"","color":"primary"}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("far fa-eye")]),_vm._v("Explore")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }