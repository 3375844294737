<template>
  <v-card>
    <v-img
      :src="randomDummyPicUrl"
      aspect-ratio="2.75"
      @error="randomizeDummyPicUrl"
    ></v-img>

    <v-card-title primary-title>
      <div>
        <h3 class="headline mb-0">Kangaroo Valley Safari</h3>
        <div> Lorem ipsum dolor sit amet null. </div>
      </div>
    </v-card-title>

    <v-card-actions>
      <v-btn text color="secondary"><v-icon left>fas fa-share-alt</v-icon> Share</v-btn>
      <v-btn text color="primary"><v-icon left>far fa-eye</v-icon>Explore</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: "DummyItem",
    data() {
      return {
        randomDummyPicUrl: '',
      }
    },
    methods: {
      randomizeDummyPicUrl() {
        const randomNumber = Math.floor(Math.random() * 100) + 1;
        const url = `https://picsum.photos/id/${randomNumber}/1024/800`;
        this.randomDummyPicUrl = url;
      }
    },
    mounted() {
      this.randomizeDummyPicUrl();
    }
  }
</script>

<style scoped>

</style>
