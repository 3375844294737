<template>
  <div>

  </div>
</template>

<script>
  import tenantApi from "../api/tenant";

  export default {
    methods: {
      async redirectToCorrectPage() {
        if (!this.$tenant && !this.$isAdmin) {
          this.$router.push({ name: 'new_account' })
          return;
        } else if (!this.$tenant) {
          this.$router.push({ name: 'tenants' })
          return;
        }
        tenantApi.setSelectedTenant(this.$tenant);

        const plans = await tenantApi.getPlans();
        if (plans.length > 0) {
          if (this.$tenant) {
            this.$router.push({ name: 'dashboards', params: { tenantId: this.$tenant } });
          }
        } else {
          this.$router.push({ name: 'plans', params: { tenantId: this.$tenant } });
        }
      },
    },
    mounted() {
      this.redirectToCorrectPage();
    },
  }
</script>

<style scoped>

</style>
