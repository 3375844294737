<template>
  <v-container fluid>
    <v-row align="start">
      <template v-for="item in 3" >
        <v-col cols="12" md="4" :key="item">
          <dummy-item />
        </v-col>
      </template>
      <v-col cols="12" md="6">
        <v-row>
          <v-col class="pt-0" cols="12">
            <v-card>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">Värit</h3>
                  <div class="body-1">Teeman värit vaihdetaan <strong>/ui/src/main.js</strong> tiedostossa. Muista myös vaihtaa theme-color <strong>/public/index.html</strong> ja <strong>/public/manifest.json</strong> -tiedostoista. Theme-color kannattaa olla yleensä astetta tummempi mitä yläpalkin väri.</div>
                </div>
              </v-card-title>

              <v-card-text>
                <v-btn class="ma-1" color="primary">Primary</v-btn>
                <v-btn class="ma-1" color="secondary">Secondary</v-btn>
                <v-btn color="accent" class="primary--text ma-1">Accent</v-btn>
                <br />
                <br />
                <v-alert
                  :value="true"
                  type="success"
                  outlined
                >
                  This is a success alert.
                </v-alert>

                <v-alert
                  :value="true"
                  type="info"
                  outlined
                >
                  This is an info alert.
                </v-alert>

                <v-alert
                  :value="true"
                  type="warning"
                  outlined
                >
                  This is a warning alert.
                </v-alert>

                <v-alert
                  :value="true"
                  type="error"
                  outlined
                >
                  This is a error alert.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">Notifikaatio</h3>
                  <div class="body-1">Testaa notifikaatiota.</div>
                </div>
              </v-card-title>
              <v-card-text>
                <v-btn class="ma-1" color="success" @click="successNotification">Success</v-btn>
                <v-btn class="ma-1" color="warning" @click="warningNotification">Warning</v-btn>
                <v-btn class="ma-1" color="error" @click="errorNotification">Error</v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title primary-title>
                <div>
                  <h3 class="headline mb-0">Push-Notifikaatio</h3>
                  <div class="body-1">Testaa Push-notifikaatiota (huom: se lähetetään kaikille tilaajille)</div>
                </div>
              </v-card-title>
              <v-card-text>
                <v-btn class="ma-1" color="success" @click="successPushNotification">Push onnistuminen</v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Sovelluksen logo ja ikonit</h3>
              <div class="body-1">Sovelluksen varsinainen logo, joka näkyy vasemmassa yläreunassa on <strong>/ui/public/img/logo.svg</strong>, jos varsinainen tiedoston nimi muuttuu niin se tulee muuttaa /ui/src/App.vue tiedostoon. Sovellusikonit ovat <strong>/ui/public/img/icons</strong> kansiossa. Sovellus ikonien teko osoitteissa <a href="http://www.favicomatic.com">http://www.favicomatic.com</a> ja <a href="https://app-manifest.firebaseapp.com/">https://app-manifest.firebaseapp.com/</a>.</div>
            </div>
          </v-card-title>
          <v-card-text>

            <div class="title mb-2">Sovelluksen logo:</div>
            <img style="height: 152px; width: auto;" src="/img/logo.svg" />

            <v-divider class="mt-3 mb-4"></v-divider>

            <v-expansion-panels>
              <v-expansion-panel class="elevation-1">
                  <v-expansion-panel-header>
                    <div class="title">Sovellusikonit:</div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                  <v-card flat>
                    <v-card-text>
                      <v-layout row wrap>
                        <template v-for="icon in iconObjects">
                          <v-flex :key="icon.name+'_img'" xs5>
                            <img :src="`/img/icons/${icon.name}`" :width="icon.width" :height="icon.height" />
                          </v-flex>
                          <v-flex :key="icon.name+'_desc'" xs7>
                            {{ icon.name }}
                          </v-flex>
                        </template>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DummyItem from './DummyItem.vue';
  import Notifications from '../../notifications';
  import axios from 'axios';

  export default {
    name: "DummyBlock",
    components: {
      DummyItem: DummyItem,
    },
    data() {
      return {
        iconObjects: [
          {name: "apple-touch-icon-57x57.png", width: '57', height: '57' },
          {name: "apple-touch-icon-60x60.png", width: '60', height: '60' },
          {name: "apple-touch-icon-72x72.png", width: '72', height: '72' },
          {name: "apple-touch-icon-76x76.png", width: '76', height: '76' },
          {name: "apple-touch-icon-114x114.png", width: '114', height: '114' },
          {name: "apple-touch-icon-120x120.png", width: '120', height: '120' },
          {name: "apple-touch-icon-144x144.png", width: '144', height: '144' },
          {name: "apple-touch-icon-152x152.png", width: '152', height: '152' },
          {name: "favicon.ico", width: '16', height: '16' },
          {name: "favicon-16x16.png", width: '16', height: '16' },
          {name: "favicon-32x32.png", width: '32', height: '32' },
          {name: "favicon-96x96.png", width: '96', height: '96' },
          {name: "favicon-128.png", width: '128', height: '128' },
          {name: "favicon-196x196.png", width: '196', height: '196' },
          {name: "icon-72x72.png", width: '72', height: '72' },
          {name: "icon-96x96.png", width: '96', height: '96' },
          {name: "icon-128x128.png", width: '128', height: '128' },
          {name: "icon-144x144.png", width: '144', height: '144' },
          {name: "icon-152x152.png", width: '152', height: '152' },
          {name: "icon-192x192.png", width: '192', height: '192' },
          {name: "icon-384x384.png", width: '384', height: '384' },
          {name: "icon-512x512.png", width: '512', height: '512' },
          {name: "mstile-144x144.png", width: '144', height: '144' },
          {name: "mstile-150x150.png", width: '150', height: '150' },
          {name: "mstile-310x150.png", width: '310', height: '150' },
          {name: "mstile-310x310.png", width: '310', height: '310' },
        ],
      }
    },
    methods: {
      successNotification() {
        this.$showSuccessNotification('Onnistunut notifikaatio');
      },
      warningNotification() {
        this.$showWarningNotification('Varoitus notifikaatio');
      },
      errorNotification() {
        this.$showErrorNotification('Epäonnistunut notifikaatio');
      },
      async successPushNotification() {
        try {
          await Notifications.registerNotifications();
          axios.post('/api/testtool/send-subscriptions', { message: 'testi push viesti' });
        } catch (err) {
          this.$handleApiError(err);
        }
      },
    },
  }
</script>

<style scoped>

</style>
